import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import { ElMessage } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import piniaPluginPersist from 'pinia-plugin-persist'
import { createPinia } from 'pinia'
import '@/assets/css/resets.css'
import axios from 'axios'
import 'element-plus/dist/index.css';
import MyPagination from '@/components/MyPagination.vue'
// import { FolderOpened,Document,DocumentCopy,List } from "@element-plus/icons-vue"
import 'element-plus/dist/index.css'
const app=createApp(App)

// axios.defaults.baseURL = '/api'
const pinia=createPinia()
// app.component('FolderOpened',FolderOpened)
// app.component('Document',Document)
// app.component('DocumentCopy',DocumentCopy)
app.component('MyPagination',MyPagination)
// app.component('List',List)
const white=['login','register','getPassword']
router.beforeEach((to, from, next) => {
  store.commit('getAccessToken')
  const token = store.state.access_token
  if (!token && !white.includes(to.name)) {
      next({ name: 'login' })
      ElMessage.warning('请先登录')
  } else if (token && to.name === 'login') {
      next({ name: 'AllCases' })
  } else (
      next()
  )
})

for (const name in ElementPlusIconsVue) {
    app.component(name, ElementPlusIconsVue[name])
  }
pinia.use(piniaPluginPersist)
app.use(store).use(router).use(ElementPlus,{locale:zhCn}).use(pinia).mount('#app')


