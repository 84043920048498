<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script setup>
import {provide} from 'vue'
import api from './api/api.js'
provide('api',api)
// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const debounce = (fn, delay) => {

let timer = null;

return function () {

  let context = this;

  let args = arguments;

  clearTimeout(timer);

  timer = setTimeout(function () {

    fn.apply(context, args);

  }, delay);

}

}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

constructor(callback) {

  callback = debounce(callback, 16);

  super(callback);

}

}


  document.addEventListener('mousewheel', function (event) {
        event = event || window.event;
        if ((event.wheelDelta && event.ctrlKey)) {
            event.preventDefault();
        }
    }, {
        capture: false,
        passive: false
    });
    document.addEventListener('DOMMouseScroll', function (event) {
        event = event || window.event;
        // event.detail 属性和 mousewheel 事件的 wheelDelta 属性是一个意思
        if ((event.detail && event.ctrlKey)) {
            event.preventDefault();
        }
    }, {
        capture: false,
        passive: false
    });
    document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
            event.preventDefault()
        }
    },{
        capture: false,
        passive: false
    });

    document.addEventListener('touchmove', function(event) {
        event.preventDefault()
    },{
        capture: false,
        passive: false
    });


</script>
<style>
#app,html,body {
width: 1920px;
height: 100%;
}
</style>
